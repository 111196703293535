import React from "react"
import { graphql } from "gatsby"

import { CollectionList as Page } from "@components/Collection/List/CollectionList"

export const query = graphql`
  query {
    collections: allSanityCollection {
      edges {
        node {
          title
          shopify {
            id: shopifyId
            handle: shopifyHandle
          }
          descriptionHtml
          image
        }
      }
    }
  }
`

export default ({ data, ...props }) => <Page {...props} {...data} />
