import React from "react"

import { withCollectionList } from "./withCollectionList"
import { CollectionListItem } from "./Item/CollectionListItem"
import { ListContainer, ListSection, ListH2, ListI } from "../CollectionStyles"

interface Props {
  collections: Array<any>
  locales: any
}

export const CollectionList = withCollectionList(({ collections, locales }: Props) => (
  <>
    <ListContainer>
      <ListH2 as={`h1`}>
        {locales.title} <ListI>{locales.allCollectionsTitle}</ListI>
      </ListH2>

      <ListSection>
        {collections?.map(collection => (
          <CollectionListItem key={collection.id} collection={collection} verticalSpacing width={`1/4`} />
        ))}
      </ListSection>
    </ListContainer>
  </>
))
