import React from "react"

import { useLanguage } from "@hooks/useLanguage"
import { useShopify } from "@hooks/useShopify"

export const withCollectionList = Component => ({ name = `CollectionList`, collections: collectionEdges }) => {
  const { edgeNormaliser } = useShopify()
  const locales = useLanguage(`collection`)

  const collections = edgeNormaliser(collectionEdges)

  Component.displayName = name
  return <Component collections={collections} locales={locales} />
}
